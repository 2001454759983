import React from 'react'

function Footer() {
  return (
    

<footer class="bg-white dark:bg-[#3d3636]">
    <div class="mx-auto w-full max-w-screen-xl">
      <div class="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-2">
        <div>
            <h2 class="mb-6 text-sm font-semibold text-white uppercase dark:text-white cursor-pointer">Contact Information</h2>
            <ul class="text-gray-500 dark:text-gray-400 font-medium">
                <li class="mb-4 cursor-pointer text-[#b8b5b5]">
                    <p className='py-2'>Address
                    : Sand Voyages Camp ,Sam Road, Kanoi Village, Jaisalmer, 345001</p>
                         

                   
                    <p className='py-2'>Phone : +91-7357511222 Or +91-8233348269</p>
                    <p className='py-2'>Email  :  info@sandvoyagescamp.in</p>
                </li>
                
            </ul>
        </div>
        <div>
            <h2 class="mb-6 text-sm font-semibold text-white uppercase dark:text-white cursor-pointer">What We Provide</h2>
            <p className='text-[#b8b5b5]'>Sand voyage camps - an ultimate destination for your comfort . It is located in Kanoi Village. The resort offers a charming atmosphere high quality hospitality to its visitors. It has luxurious and executive tents that serve as a home away from home. These are well furnished tents accomodating all basic amenities. Delicious Indian and multicuisine food gives essence and aroma of Rajasthan's historical culture and also caters to varied taste of travellers.  Read More</p>
        </div>
       
        
        
    </div>
    <div class="px-4 py-6 bg-gray-100 dark:bg-[#3b3a3a] md:flex md:items-center md:justify-between">
        <span class="text-sm text-white dark:text-white sm:text-center">© 2023 <a href="/">Copyright Sand Voyages Camp  .</a> All Rights Reserved
        </span>
        <div class="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
            
              
              
        </div>
      </div>
    </div>
</footer>

  )
}

export default Footer
