import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import bg from "../../assets/bedouin-camel-drivers-thar-desert-sunset-jaisalmer-india_1168123-3653.jpg";

import Content from "./Content";

function WhoWe() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // duration of animation in milliseconds
      easing: "ease-in-out", // easing function for the animation
      once: true, // whether animation should happen only once
    });
  }, []);

  return (
    <>
      <div
        className="bg-cover bg-center lg:h-[65vh] opacity-85"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
         <div
        className='bg-cover bg-center h-[40vh] lg:h-[65vh] opacity-85'
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          
        }}
      >

       
         
        <h2
        className="text-[#000000] flex justify-center pt-32 items-center font-bold text-[37px] lg:text-[45px] "
       
      >
        Activities
      </h2>
      
      
        
      </div>
      </div>
      <Content />
    </>
  );
}

export default WhoWe;
