import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import img1 from "../../assets/camel-safari1.jpg";
import img2 from "../../assets/museum.jpeg";
import img3 from "../../assets/jeep-safari.jpg";

function Content() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in ms
      easing: "ease-in-out", // easing function
      once: true, // whether animation should happen only once
    });
  }, []);

  return (
    <div className="px-10 py-10">
      <h2
        className="py-3 flex justify-center font-bold text-[17px] lg:text-[25px]"
        data-aos="fade-up"
      >
        Experiences We Provide
      </h2>
      <div className="py-4 px-6">
        <h3 className="font-bold">CAMEL SAFARI</h3>
        <br />
        <div className="lg:flex py-5" data-aos="fade-up">
          <p className="lg:pr-48 ">
            A camel safari is the perfect way to discover desert regions –
            especially areas as historically and culturally rich as Rajasthan.
            Ensconce yourself contentedly on the back of an ungulate and admire
            the sand dunes around you extending for miles – as far as your eye
            can see. A camel can take you places where a jeep or ordinary motor
            vehicle will struggle to reach (and only the camel can successfully
            navigate the dunes). Not for nothing is the camel known as the ‘ship
            of the desert’; for centuries, it was the only means of transport
            available in these often inhospitable stretches of land. A camel
            safari, then, makes for a magical journey, one unlike any other
            you’ll ever experience.
            <br /> <br /> There’s probably no better (or more romantic) way of
            seeing Rajasthan than on the back of a dromedary. Crisscross the
            sands during the day – get up close with Rajasthan’s splendid
            architecture and wonderful hospitality – and sleep under the stars
            at night. Boasting the awesome vastness of the Thar Desert,
            Rajasthan is quite obviously the top destination for camel safaris
            in India. You can tread the caravan routes of old, passing hamlets
            and desert communities almost unchanged over time.
          </p>
          <img src={img1} alt="" className="h-44 px-0 lg:px-0 lg:pr-52 md:px-52" />
        </div>

        <div>
          <h3 className="py-5 font-extrabold" data-aos="fade-right">
            JEEP SAFARI
          </h3>
          <div className="lg:flex" data-aos="fade-up">
            <p className="lg:pr-48">
              Leave the crowds of city behind on this Jeep safari tour into the
              desert. Your safari guides will drive you in a 4x4 Jeep vehicle
              over towering sand dunes, deep into the heart of the desert. As
              the sun sets over the soft sands, watch for lantern light in the
              distance, signaling a traditional welcome at the desert camp.
              <br /> <br /> Our Camp offers you a cultural walk in the Lakhamana
              village, where you see the Traditional life, Mud Tandoors, Water
              Wells and mud storage system for grains. The villages in Jaisalmer
              are made up of hamlets, the most basic form of civilization,
              consist of a collection of huts that are circular and have
              thatched roofs. The walls are covered with the sand, cow dung, and
              hay. The bonfire between these hamlets is mesmerizing.
            </p>
            <img src={img2} alt="" className="h-52 lg:pr-52" />
          </div>
        </div>

        <div>
          <h3 className="py-5 font-extrabold" data-aos="fade-right">
            MUSEUM & TEMPLE TOUR
          </h3>
          <div className="lg:flex" data-aos="fade-up">
            <p className="lg:pr-48">
              The Museum & Temple Tour to the city includes Desert National
              Park, Akal Fossil Park, The Folklore Museum, Desert Cultural
              Center, Government Museum, Thar Heritage Museum with many other
              options. Also visit of Some temples like Baba Ramdev Mandir, or
              Tanot Mata Mandir, Shanti Nath Mandir and Rishabh Dev Mandir.
            </p>
            <img src={img3} alt="" className="h-52 lg:pr-52" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;
