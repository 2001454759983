import React from 'react'

import Work from './Content/Work'
import bg from '../../assets/IMG_20241004_123458.jpg';

function About() {
  return (

    <div>
        <div
        className='bg-cover bg-center h-[40vh] lg:h-[65vh] opacity-85'
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          
        }}
      >

       
         
        <h2
        className="text-black flex justify-center pt-40 lg:pt-52 items-center font-bold text-[37px] lg:text-[45px] "
       
      >
        About Us
      </h2>
      
      
        
      </div>
      <Work />
        
    </div>
  )
}

export default About
