import React from "react";
import img1 from "../../assets/1 (1).png";
import img2 from "../../assets/2 (1).png";
import img3 from "../../assets/3.png";
import img4 from "../../assets/4.png";
import img5 from "../../assets/5.jpg";
import img6 from "../../assets/6.jpg";
import img7 from "../../assets/7.jpg";
import img8 from "../../assets/8.jpg";
import img9 from "../../assets/9.jpg";
import img10 from "../../assets/10.jpg";
import img11 from "../../assets/11.jpg";
import img12 from "../../assets/12.jpg";

function Cards() {
  return (
    



<div class="grid grid-cols-2 md:grid-cols-3 gap-4">
    <div>
        <img class="h-auto max-w-full rounded-lg" src={img1} alt="" />
    </div>
    <div>
        <img class="h-auto max-w-full rounded-lg" src={img2} alt="" />
    </div>
    <div>
        <img class="h-auto max-w-full rounded-lg" src={img3} alt="" />
    </div>
    <div>
        <img class="h-auto max-w-full rounded-lg" src={img4} alt="" />
    </div>
    <div>
        <img class="h-auto max-w-full rounded-lg" src={img5} alt="" />
    </div>
    <div>
        <img class="h-auto max-w-full rounded-lg" src={img6} alt="" />
    </div>
    <div>
        <img class="h-auto max-w-full rounded-lg" src={img7} alt="" />
    </div>
    <div>
        <img class="h-auto max-w-full rounded-lg" src={img8} alt="" />
    </div>
    <div>
        <img class="h-auto max-w-full rounded-lg" src={img9} alt="" />
    </div>
    <div>
        <img class="h-auto max-w-full rounded-lg" src={img10} alt="" />
    </div>
    <div>
        <img class="h-auto max-w-full rounded-lg" src={img11} alt="" />
    </div>
    <div>
        <img class="h-auto max-w-full rounded-lg" src={img12} alt="" />
    </div>
</div>


  );
}

export default Cards;
