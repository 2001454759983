import img1 from "../../assets/sammer_package.jpg"
import img2 from "../../assets/wine.jpg"
import img3 from "../../assets/jeep-safari.jpg"

const FeatureSection = () => {
  return (
 
<div className="lg:flex gap-5 justify-center px-4 py-4">
<div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg- dark:">
    <a href="/">
        <img class="rounded-t-lg" src={img1} alt="" />
    </a>
    <div class="p-5">
        <a href="/">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-black dark:text-black">Jaisalmer</h5>
        </a>
        <p class="mb-3 font-normal text-black dark:text-black">A town in the Indian state of Rajasthan, with Palace It, was once known as Jaiswal with the palace and several jain temples, Situated along the Thar Desert, It is one of the most admired tourist destinations across the world.</p>
        
    </div>
</div>

<div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg- dark:">
    <a href="/">
        <img class="rounded-t-lg" src={img2} alt="" />
    </a>
    <div class="p-5">
        <a href="/">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-black dark:text-black">Restaurant</h5>
        </a>
        <p class="mb-3 font-normal text-black dark:text-black">Sand Voyages Camp Serving an international cuisine along with traditional Rajasthani and Indian dishes . Our stylish dining restaurant offers inspiring views of the Desert. Enjoy delicious buffet Dinners around the campfire or in the Restaurant.</p>
        
    </div>
</div>

<div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg- dark:">
    <a href="/">
        <img class="rounded-t-lg" src={img3} alt="" />
    </a>
    <div class="p-5">
        <a href="/">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-black dark:text-black">Activities</h5>
        </a>
        <p class="mb-3 font-normal text-black dark:text-black">Experience amazing activities like camel safari in the Sam (Sand Dunes) the Thar Desert while watching the sunset. There are various activities which you can do and enjoy your vacation in Jaisalmer. Check out various activities.</p>
       
    </div>
</div>
</div>
  );
};

export default FeatureSection;