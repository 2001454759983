import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import img1 from "../../../assets/22642c62c1b4dc33f5d28d04e532a5c9.jpg";
import img2 from "../../../assets/IMG-20211017-WA0031.jpg";

function Work() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in ms
      easing: "ease-in-out", // easing function
      once: true, // whether animation should happen only once
    });
  }, []);

  return (
    <div className="px-10 py-5">
      <h2
        className="py-3 flex justify-center font-bold text-[17px] lg:text-[25px] text-[#000]"
        data-aos="fade-up"
      >
        Sand Voyages Camp
      </h2>
      <div className="py-4 px-6">
        <div className="lg:flex" data-aos="fade-up">
          <p className="lg:pr-48">
            Sand voyage camps - an ultimate destination for your comfort . It is
            located near sam dunes. The resort offers a charming atmosphere high
            quality hospitality to its visitors. It has luxurious and executive
            tents that serve as a home away from home. These are well furnished
            tents accomodating all basic amenities.Delicious Indian and
            multicuisine food gives essence and aroma of Rajasthan's historical
            culture and also caters to varied taste of travellers.
            <br />
            <br />
            The guests may visit Patwon ki Haveli, Bada Bagh ,Mandir Palace and
            Jaisalmer Fort which are located at a distance of 35 km. They can
            also enjoy camel and jeep safaris at Sam Sand dunes. The nearest
            airport and railway station are Jaisalmer airport and Jaisalmer
            Railway station. All in all, Sand Voyages serves as an ideal choice
            for spending peaceful and exotic vacation enjoying the beauty of
            dunes and desert life at Jaisalmer.
            <br />
            <br />
            Our commitment to offering tailored hospitality to our guests is
            reflected in the various facilities provided at the resort. You can
            decide your accommodation from among the 40 opulent guest houses,
            that include luxury tents and Executive Tents with private veranda,
            all displaying the traditional Rajasthani influence in interior
            designs. We, the team at Sand Voyages Camp make every effort to
            provide great comforts to you. Our commitment to bring out the
            spirit of Indian hospitality and present it flawlessly to our guests
            from India and abroad is the onus we take on us with utmost
            importance.
          </p>
          <img src={img1} alt="" className="h-60  lg:pr-52" />
        </div>

        <div>
          <h3
            className="py-5 font-extrabold flex justify-center text-[#000]"
            data-aos="fade-up"
          >
            CAMP FACILITIES
          </h3>
          <div className="lg:flex">
            <div className="lg:flex" data-aos="fade-up">
              <img src={img2} alt="" className="h-52 lg:pr-10 md:px-10 lg:px-0" />
              <p className="lg:pr-48">
                <span className="font-bold">Hotel Facilities</span>
                <li>
                  <span className="font-bold">
                    Express check-in & check-out.
                  </span>
                </li>
                <li>
                  <span className="font-bold">24-hour Hot & Cold water</span>
                </li>
                <li>
                  <span className="font-bold">Multi-cuisine restaurant</span>{" "}
                </li>
                <li>
                  <span className="font-bold">24-hour front desk</span>{" "}
                </li>
                <li>
                  <span className="font-bold">
                    Wi Fi access in all Public Area
                  </span>{" "}
                </li>
                <li>
                  <span className="font-bold">
                    Complimentary on site parking
                  </span>{" "}
                </li>
                <li>
                  <span className="font-bold">
                    laundry service-Available On Charge
                  </span>{" "}
                </li>
                <li>
                  <span className="font-bold">
                    Travel Desk-Available On Charge
                  </span>{" "}
                </li>
              </p>
            </div>
            <div className="lg:flex" data-aos="fade-up">
              <p className="lg:pr-48">
                <span className="font-bold py-4">Recreational Activities</span>
                <li>
                  <span className="font-bold">Cricket Kit</span>
                </li>
                <li>
                  <span className="font-bold">Tug of War</span>
                </li>
                <li>
                  <span className="font-bold">Volley Ball</span>{" "}
                </li>
                <li>
                  <span className="font-bold">Playing cards</span>{" "}
                </li>
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center text-[#000]">
          <h3
            className="py-10 font-extrabold flex justify-center "
            data-aos="fade-up"
          >
            DINING AND CUISINE
          </h3>

          <div className="lg:flex lg:justify-center" data-aos="fade-up">
            <p className="lg:">
              Sand Voyages Camp Serving an international cuisine along with
              traditional Rajasthani and Indian dishes . Our stylish dining
              restaurant offers inspiring views of the Desert. Enjoy delicious
              buffet Dinners around the campfire or in the Restaurant. An
              extensive A la carte menu is available throughout the day; the
              buffet service is presented at breakfast and dinner. Guests
              looking for a luxury dining restaurant in Sam will not be
              disappointed by our magnificent indoor restaurant, with its daily
              changing menu of authentic Rajasthani specialties and pan-Indian.
              In the winter, it becomes charmed by the open wood fireplace,
              while a bonfire, candles and folk musicians lend ambience to the
              outside courtyard.
              <br />{" "}
              <span className="font-bold flex justify-center">
                Thank you for choosing Sand Voyages Camp. We look forward to
                serving you.
              </span>
            </p>
          </div>
          <h3
            className="py-10 font-extrabold flex justify-center "
            data-aos="fade-up"
          >
            IN-ROOM DINING
          </h3>

          <div className="lg:flex lg:justify-center" data-aos="fade-up">
            <p className="lg:">
              Sometimes the perfect place to go is no place at all. In-room
              dining service presents a high quality dining experience in the
              comfort of your room. On offer is a wide range of Indian cuisines
              for all meals and snacks.
              <br />{" "}
              <span className="font-bold flex justify-center">
              Timing: For room guests only, served till 11.30 PM.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
