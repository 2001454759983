import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Cards from './Cards';


function OurProducts() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // duration of animation in milliseconds
      easing: 'ease-in-out', // easing function for the animation
      once: true, // whether animation should happen only once
    });
  }, []);

  return (
    <>
    
      
        <div
        className='bg-cover bg-center  opacity-85'

      >

        < div className='py-10 mx-10 z-10 placeholder bg-[#d4efd2] bg-opacity-60 text-[#000] '>
         
        <h2
        className="py-10 flex justify-center font-bold text-[37px] lg:text-[25px] "
        data-aos="fade-up"
      >
        Gallery
      </h2>
      <div div className="py-4 px-6">
        <div className="lg:flex" data-aos="fade-up">
          <p className="lg:pr-48">
            
          </p>
        </div>
      </div>
      
        </div>
        
      </div>
      <Cards />
      
    </>
  );
}

export default OurProducts;
