import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./component/Home/Home";
import Footer from "./component/footer/Footer";
import About from "./component/About/About";
import Navbar from "./component/nav/Navbar";
import Preloader from "./component/preloader/Preloader";
import OurProducts from "./component/OurProducts/OurProducts";
import WhoWe from "./component/WhoWeAre/WhoWe";

const App = () => {
  const [loading, setLoading] = useState(true); // State to control preloader

  // Use useEffect to automatically stop the loader after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000); // 5 seconds

    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  return (
    <>
      {loading ? (
        // Render Preloader while loading is true
        <Preloader />
      ) : (
        // Render the main content once loading is done
        <Router>
          <div className="h-full bg-pattern bg-repeat max-w-[1800px] mx-auto overflow-hidden">
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/product" element={<OurProducts />} />
              <Route path="/whoweare" element={<WhoWe />} />
            </Routes>
            <Footer />
          </div>
        </Router>
      )}
    </>
  );
};

export default App;
