import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import bg1 from '../../assets/camp9-Medium.jpg'; // Image 1
import bg2 from '../../assets/camp8-Medium.jpg'; // Image 2
import bg3 from '../../assets/adv5.jpg'; // Image 3
import FeatureSection from './FeatureSection';
import About1 from './About1';

function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // duration of animation in milliseconds
      easing: 'ease-in-out', // easing function for the animation
      once: true, // whether animation should happen only once
    });
  }, []);

  return (
    <>
      {/* Carousel Section */}
      <Carousel
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={2000}
        showStatus={false}
      >
        <div>
          <img src={bg1} alt="Background 1" className="h-[50vh] md:h-[60vh] object-cover" />
        </div>
        <div>
          <img src={bg2} alt="Background 2" className="h-[50vh] md:h-[60vh] object-cover" />
        </div>
        <div>
          <img src={bg3} alt="Background 3" className="h-[50vh] md:h-[60vh] lg:h-[70vh] object-cover" />
        </div>
      </Carousel>

      {/* Content Section */}
      <div className='py-10 mx-10 z-10 placeholder'>
        
      </div>
      <About1 />
      
      <FeatureSection />
    </>
  );
}

export default Home;
