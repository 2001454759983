import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function About1() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in ms
      easing: "ease-in-out", // easing function
      once: true, // whether animation should happen only once
    });
  }, []);

  return (
    <div className="px-10 py-10">
      <h2
        className="py-3 flex justify-center font-bold text-[17px] lg:text-[30px] "
        data-aos="fade-up"
      >
        About Us 
      </h2>
      <div div className="py-4 lg:px-6 ">
        <div className="lg:flex md:flex md:justify-center md:" data-aos="fade-up">
         
          <p className="lg:px-10">
          Sand voyage camps - an ultimate destination for your comfort . It is located in Kanoi Village. The resort offers a charming atmosphere high quality hospitality to its visitors. It has luxurious and executive tents that serve as a home away from home. These are well furnished tents accomodating all basic amenities.Delicious Indian and multicuisine food gives essence and aroma of Rajasthan's historical culture and also caters to varied taste of travellers. The guests may visit Patwon ki Haveli, Bada Bagh ,Mandir Palace and Jaisalmer Fort which are located at a distance of 35 km.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About1;
